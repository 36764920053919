<template>
  <v-container fluid tag="section" class="parent-container">
    <div class="container-content">
      <v-form ref="collectionDetails" class="mt-5">
        <div
          class="md-layout-item md-small-size-100"
          style="margin-bottom:35px;"
        >
          <v-text-field
            v-model="collectionName"
            maxlength="200"
            label="Collection Name"
            :rules="[rules.required]"
          ></v-text-field>
        </div>
        <div
          v-if="getIsAccountManager() && isNew"
          class="md-layout-item md-small-size-100 "
        >
          <v-autocomplete
            class="selection-field"
            clearable
            v-model="model.customer"
            :items="customerList"
            item-text="full_name"
            item-value="id"
            label="Buyer Name"
            outlined
            ref="customerName"
            :rules="customerValidationError"
          >
            <template v-slot:item="{ item }">
              <span class="list-content">{{ item.full_name }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span>{{ item.full_name }}</span>
            </template>
          </v-autocomplete>
        </div>
        <div class="md-layout-item md-small-size-100">
          <v-select
            class="selection-field"
            v-model="selectedCurrency"
            :items="currencies"
            label="Currency"
            dense
            outlined
            item-value="currency_code"
            color="#442D65"
            :error-messages="currencyValidationError"
            :disabled="saving"
          >
            <template v-slot:item="{item}">
              {{ item.display_name }}
            </template>
            <template v-slot:selection="{item}">
              <span>{{ item.display_name }}</span>
            </template>
          </v-select>
        </div>
        <div v-for="(pkg, id) in packages" :key="id">
          <v-radio-group v-model="selectedPackage" class="radio-group">
            <v-radio
              color="#47C0BE"
              :label="pkg.display_name"
              :key="id"
              :value="pkg.id"
              @change="changePackage()"
              class="text-color"
            />
          </v-radio-group>
          <time-line-package :id="id" :selectedPackage="selectedPackage" />
        </div>
        <div>
          <md-card-actions style="padding:8px 0px;">
            <ui-button
              id="saveButton"
              class="md-raised"
              :disabled="saving"
              @click="save()"
              title="Save"
            />
          </md-card-actions>
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "../../restAdapter";
import auth from "../../auth";
import notification from "../../notification";
import TimeLinePackage from "../Inquiry/TimeLinePackage.vue";
import {required, requiredIf} from "vuelidate/lib/validators";
import routes from '../../router/routes';

export default {
  name: "CreateCollection",
  components: {
    TimeLinePackage,
  },
  data: () => ({
    userId: null,
    packages: [],
    openPackageWarning: false,
    isNew: true,
    model: {
      customer: null,
    },
    customerList: [],
    saving: false,
    rules: {
      required: (value) => !!value || "This field is Required.",
    },
    collectionData: [],
    collectionId: null,
    currencies: [],
  }),

  // validations: {
  //   model: {
  //     currency: {
  //       required,
  //     },
  //   },
  // },
  props: {
    collectionName: {
      type: String,
      default: null,
    },
    selectedPackage: {
      type: Number,
      default: 2,
    },
    selectedCurrency: {
      type: String,
      default: "USD",
    },
  },
  mounted() {
    this.collectionId = this.$route.params.id;
    this.isNew = !this.$route.params.id;
    this.userId = auth.user.id;
    this.fetchCustomerList();
    this.getPackages();
    this.getCurrencies().then((response) => {
      this.currencies = response.data.currencies;
    });
  },

  computed: {
    customerValidationError() {
      return [
        (v) => {
          if (!this.model.customer) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    // currencyValidationError() {
    //   if (!this.model.currency && this.$v.model.currency.$dirty) {
    //     return "The currency is required";
    //   }
    //   return null;
    // },
  },

  methods: {
    getIsAccountManager() {
      return auth.user.userType === "account_manager";
    },

    fetchCustomerList() {
      restAdapter.get(`/api/view_customers`).then((response) => {
        this.customerList = response.data;
      });
    },

    getPackages() {
      restAdapter.get(`/api/inquiries_packages`).then((response) => {
        response.data.packages.forEach((inquiryPackage) => {
          if (inquiryPackage.package_name == "Low") {
            inquiryPackage.display_name = "High-speed production - 8 weeks";
          } else if (inquiryPackage.package_name == "Medium") {
            inquiryPackage.display_name =
              "Medium lead time production - 16 weeks";
          } else if (inquiryPackage.package_name == "High") {
            inquiryPackage.display_name =
              "Longer lead time production - 22 weeks+";
          }

          this.packages.push(inquiryPackage);
        });
        this.packages.reverse();
      });
    },

    changePackage() {
      if (this.showPackageWarning) {
        this.openPackageWarning = true;
      }
    },

    save() {
      if (!this.$refs.collectionDetails.validate()) {
        return;
      }
      if (this.isNew) {
        this.createCollection();
      } else {
        this.updateCollection();
      }
    },
    createCollection() {
      restAdapter
        .post(`/api/collection`, {
          name: this.collectionName,
          buyer_id: this.model.customer,
          currency: this.selectedCurrency,
          package: this.selectedPackage,
        })
        .then((response) => {
          notification.success("Collection created successfully");
          this.$router.push({name: routes.COLLECTION, params: {id: response.data.collection.id}})
        })
        .catch((error) => {
          notification.error("Collection created unsuccessfully");
        });
    },
    updateCollection() {
      restAdapter
        .put(`/api/collection/${this.collectionId}`, {
          name: this.collectionName,
          buyer_id: this.model.customer,
          currency: this.selectedCurrency,
          package: this.selectedPackage,
        })
        .then((response) => {
          notification.success("Collection updated successfully");
          this.$emit("update-event", 1);
        })
        .catch((error) => {
          notification.error("Collection updated unsuccessfully");
        });
    },
    getCurrencies() {
      return restAdapter.get("/api/inquire_currencies");
    },
    getCurrencyName(code) {
      const currency = this.currencies.find(
        (currency) => currency.currency_code == code
      );
      return currency && currency.display_name;
    },
  },
};
</script>
<style lang="scss" scoped>
.parent-container {
  margin-top: 10px;
  width: 70%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.container-content {
  width: 500px;
}

.md-field.md-theme-default.md-focused label {
  color: $tableBorder !important;
}

.md-field.md-theme-default:before {
  background-color: $tableBorder !important;
}

.selection-field::v-deep .v-select__selections {
  color: $tableBorder;
}

.list-content {
  color: $tableBorder !important;
}

.theme--light.v-sheet {
  background-color: #f3edf7 !important;
}

.v-text-field__details {
  padding: 0px !important ;
}

.text-color::v-deep .v-label {
    color: $tableBorder !important;
}

</style>
