<template>
    <div>
        <md-card class="package-detail" v-if="selectedPackage && selectedPackage === 1 && id === 2">
            <md-toolbar class="toolbar-title md-dense">
                <h3 class="package-title md-title">Package Conditions</h3>
            </md-toolbar>
            <md-card-content>
                <ul class="text-left package-content">
                    <div v-for="(packageData, index) in package1" v-bind:key="index">
                        <li>{{packageData[index]}}</li>
                    </div>
                </ul>
            </md-card-content>
        </md-card>
        <md-card class="package-detail" v-if="selectedPackage && selectedPackage === 2 && id === 1">
            <md-toolbar class="toolbar-title md-dense">
                <h3 class="package-title md-title">Package Conditions</h3>
            </md-toolbar>
            <md-card-content>
                <ul class="text-left package-content">
                    <div v-for="(packageData, index) in package2" v-bind:key="index">    
                        <li>{{packageData[index]}}</li>
                    </div>
                </ul>
            </md-card-content>
        </md-card>
        <md-card class="package-detail" v-if="selectedPackage && selectedPackage === 3 && id === 0">
            <md-toolbar class="toolbar-title md-dense">
                <h3 class="package-title md-title">Package Conditions</h3>
            </md-toolbar>
            <md-card-content>
                <ul class="text-left package-content">
                    <div v-for="(packageData, index) in package3" v-bind:key="index">
                        <li>{{packageData[index]}}</li>
                    </div>
                </ul>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>

export default {
    data: ()=> ({
        package1: [
            {0: 'Applicable for highly technical styles developing for the 1st time.'},
            {1: 'Buyer must pay the sample development cost and 70% bulk production cost prior to sample development.'},
            {2: 'Need comments for the samples within 4 working days after receiving the samples.'},
            {3: 'Bulk production lead time based on 1000pcs/order in one production line.'},
            {4: 'Bulk styles planned for 3rd party final inspection.'},
            {5: 'Bulk shipment will be couriered or air freight.'},
            {6: 'These deadlines are estimates and there can be changes in deadlines.'}
        ],
        package2: [
            {0: 'Buyer must pay the tech pack development cost and fabric sourcing cost at the same time.'},
            {1: 'Buyer must pay the sample development cost and 70% bulk production cost prior to sample development.'},
            {2: 'Need comments for the samples within 2 working days after receiving the samples.'},
            {3: 'Bulk production lead time based on 1000pcs/order in one production line.'},
            {4: 'Bulk styles planned for 3rd party final inspection.'},
            {5: 'Bulk shipment will be air freight.'},
            {6: 'These deadlines are estimates and there can be changes in deadlines.'}
        ],
        package3: [
            {0: 'This is mainly applicable for repeat orders or non-complex products.'},
            {1: 'Buyer must provide the finalised tech pack.'},
            {2: 'Buyer must pay the sample production cost and 70% of bulk garment cost at the beginning of the process.'},
            {3: 'Buyer must provide the sample fabric or the factory will use the best matching fabric for sample production.'},
            {4: 'Sample fabric must arrive within 4 working days after cost confirmation from the buyer if the buyer supplies the fabric.'},
            {5: 'Need approval for the samples within 2 working days after receiving the samples.'},
            {6: 'If the buyer needs an extra revision, another 10 working days will add to the standard lead time.'},
            {7: 'Bulk fabric should be in-house to the factory 2 days before the sample approval if the buyer arranges the fabric.'},
            {8: 'Bulk production lead time based on 500pcs/order in one production line.'},
            {9: 'Bulk styles planned for 3rd party Final Inspection.'},
            {10: 'Bulk shipment will be air freight.'},
            {11: 'These deadlines are estimates and there can be changes in deadlines.'}
        ],
        
    }),

    props: {
        id: {
            type: Number,
            default: null
        },
        selectedPackage: {
            type: Number,
            default: null
        },
    },
}

</script>

<style lang="scss" scoped>

.package-detail {
    background: $paginateBackground;
    border: 0.5px solid $tableBorder;
    border-radius: 8px;
    margin-bottom: 15px;
}

.package-content{
    padding: 15px 50px 18px 54px;
    color: $tableBorder;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.toolbar-title {
  background-color: $tableBorder !important;
  border-radius: 6px 6px 0px 0px;
  box-shadow: none;
}

.package-title{
    color: #fff !important;
}

.md-toolbar.md-theme-default.md-primary {
    background-color: $tableBorder !important;
    color: #fff;
    border-radius: 6px 6px 0px 0px;
    box-shadow: none;
}


</style>